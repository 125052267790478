import React from 'react';
import ImageWatermark from './components/ImageWatermark';

function App() {
  return (
    <div className="App">
      <ImageWatermark />
    </div>
  );
}

export default App;